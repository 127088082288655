<script lang="ts" setup>
const socialMediaLinks = useSocialLinks();
const { t } = useI18n();

type FooterLink = {
  title: string;
  href: string;
};

const pageLinks = [
  { title: t('home'), href: '/' },
  { title: t('search'), href: '/suche' },
  { title: t('overview'), href: '/dashboard' },
  { title: t('message', { count: 2 }), href: '/messaging' },
  { title: t('match', { count: 2 }), href: '/dashboard/matches' },
  { title: t('watch-list', 1), href: '/dashboard/liked' },
  { title: t('prospect'), href: '/dashboard/interested' },
  { title: t('account'), href: '/account/settings' },
  { title: t('to-log-out'), href: '/account/logout' },
] as FooterLink[];

const cityLinks = [
  { title: t('berlin'), href: '/berlin' },
  { title: t('bonn'), href: '/bonn' },
  { title: t('duesseldorf'), href: '/duesseldorf' },
  { title: t('frankfurt'), href: '/frankfurt-am-main' },
  { title: t('freiburg'), href: '/freiburg-im-breisgau' },
  { title: t('hamburg'), href: '/hamburg' },
  { title: t('munich'), href: '/muenchen' },
  { title: t('cologne'), href: '/koeln' },
  { title: t('potsdam'), href: '/potsdam' },
  { title: t('stuttgart'), href: '/stuttgart' },
  { title: t('vienna'), href: '/wien' },
  { title: t('zuerich'), href: '/zuerich' },
  { title: t('additional-city', { count: 2 }), href: '/staedte' },
] as FooterLink[];

const infoLinks = [
  { title: t('success-story', 2), href: '/erfolgsgeschichten' },
  { title: t('for-exchangers'), href: '/wohnungstausch/information-tauscher' },
  { title: t('for-landlords'), href: '/wohnungstausch/information-vermieter' },
  { title: t('price', { count: 2 }), href: '/preise' },
  { title: t('frequently-asked-question', { count: 2 }), href: '/faq' },
  { title: t('about-us'), href: '/ueber-uns' },
  { title: t('for-cities-and-companies'), href: '/wohnungstausch-fuer-unternehmen' },
  { title: t('partners-and-links'), href: '/partner' },
  { title: t('agb'), href: '/agb' },
  { title: t('privacy'), href: '/datenschutz' },
  { title: t('cookie-consent'), href: '/datenschutz?cmpscreen' },
  { title: t('legal-notice'), href: '/impressum' },
  { title: t('netiquette'), href: '/netiquette' },
] as FooterLink[];

const contactLinks = [
  { title: t('contact-form'), href: '/kontakt' },
  { title: t('blog'), href: '/blog' },
  { title: t('press'), href: '/presse' },
  { title: t('career'), href: '/karriere' },
] as FooterLink[];
</script>

<template>
  <footer class="tw-page-footer">
    <div class="tw-page-footer--body">
      <div class="tw-page-footer--link-lists">
        <div class="tw-page-footer--link-list link-list--personal">
          <p>{{ t('you-with-us') }}</p>
          <ul>
            <li v-for="link in pageLinks" :key="link.href">
              <a :href="link.href" :title="link.title">{{ link.title }}</a>
            </li>
          </ul>
        </div>

        <div class="tw-page-footer--link-list link-list--cities">
          <p>{{ t('city', { count: 2 }) }}</p>
          <ul>
            <li v-for="link in cityLinks" :key="link.href">
              <a :href="link.href" :title="link.title">{{ link.title }}</a>
            </li>
          </ul>
        </div>

        <br />

        <div class="tw-page-footer--link-list link-list--infos">
          <p>{{ t('info', { count: 2 }) }}</p>
          <ul>
            <li v-for="link in infoLinks" :key="link.href">
              <a :href="link.href" :title="link.title">{{ link.title }}</a>
            </li>
          </ul>
        </div>

        <div class="tw-page-footer--link-list link-list--contact">
          <p>{{ t('contact') }}</p>
          <ul>
            <li v-for="link in contactLinks" :key="link.href">
              <a :href="link.href" :title="link.title">{{ link.title }}</a>
            </li>
          </ul>

          <ul class="link-list--social-media">
            <li v-for="link in socialMediaLinks" :key="link.href">
              <a :href="link.href" :title="link.title" target="_blank" rel="noopener noreferrer">
                <Icon :name="link.icon" class="social-media-icons" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <tw-layout-scroll-to-top />
    </div>

    <div class="tw-widget widget--copyright">{{ new Date().getFullYear() }} &copy; Tauschwohnung GmbH. Alle Rechte vorbehalten.</div>
  </footer>
</template>

<style lang="scss" scoped>
@import '~/assets/styles/tw-mixins.scss';

.tw-page-footer {
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin-top: 2em;

  .tw-page-footer--body {
    position: relative;
    margin-bottom: 1em;
    padding: 3em 1em;
    background-color: #151b3c;

    > * {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  :deep(.tw-trust-pilot-widget) {
    margin-top: 2em;
  }
}

.tw-page-footer--link-lists {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  color: #fff;

  > * {
    padding: 1em;
  }

  > * {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
  }

  p {
    padding-bottom: 0.4rem;
    margin-bottom: 0.8rem;
    border-bottom: 1px solid #fa9400;
    color: #fff;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    a {
      display: inline-block;
      text-decoration: none;
      padding: 0.1em;

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .link-list--contact {
    .link-list--social-media,
    .link-list--support-phone {
      margin-top: 1em;
    }

    .link-list--social-media {
      > * {
        display: inline-block;
        margin-right: 0.5em;
      }
    }

    .link-list--support-phone {
      svg,
      img {
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 0.5em;
      }
    }
  }
}

.tw-widget.widget--copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.social-media-icons {
  width: 1.5rem;
  height: 1.5rem;
}

@include tw-is-tablet {
  .tw-page-footer--link-lists {
    & > * {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 50%;
    }
  }
}

@include tw-is-desktop {
  .tw-page-footer--link-lists > * {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 25%;
  }
}
</style>
